import ApiService from "@/services/api.service.js";

const state = {
  wizard_survey: {},
  wizard_project_id: -1,
  wizard_client_id: -1,
  wizard_form_id: -1,
  wizard_contact_id: -1
};
const getters = {
  wizard_survey(state) {
    return state.wizard_survey;
  },
  wizard_project_id(state) {
    return state.wizard_project_id;
  },
  wizard_client_id(state) {
    return state.wizard_client_id;
  },
  wizard_form_id(state) {
    return state.wizard_form_id;
  },
  wizard_contact_id(state) {
    return state.wizard_contact_id;
  }
};
const actions = {
  getWizardSurvey(context, payload) {
    console.log(payload);
    return ApiService.api.projects
      .projects_read({ id: payload })
      .then(survey => {
        console.log(survey);
        context.commit("setWizardSurvey", survey.obj);
      })
      .catch(response => {
        console.log(response);
      });
  }
};
const mutations = {
  setWizardProjectID(state, id) {
    state.wizard_project_id = id;
  },
  setWizardClientID(state, id) {
    state.wizard_client_id = id;
  },
  setWizardFormID(state, id) {
    state.wizard_form_id = id;
  },
  setWizardContactID(state, id) {
    state.wizard_contact_id = id;
  },
  setWizardSurvey(state, wizard_survey) {
    state.wizard_survey = wizard_survey;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
