import ApiService from '@/services/api.service.js'

const state = {
    form_loading: false,
    form_errors: {},

    forms: [],
    form: {},
    wizard_form: {},
}
const getters = {
    form_loading(state) {
        return state.form_loading;
    },
    form(state) {
        return state.form;
    },
    forms(state) {
        return state.forms;
    },
    wizard_form(state) {
        return state.wizard_forms;
    }
}
const actions = {
    getForms(context, payload) {
        context.commit("setFormLoadingBegin");
        return ApiService.api.forms.forms_list().then((forms) => {
            console.log(forms)
            context.commit("setForms", forms.obj);
            context.commit("setFormLoadingComplete");
        }).catch((response) => {
            console.log(response);
            context.commit("setFormErrors", response);
        });
    },
    postForm(context, payload) {
        console.log(payload)
        context.commit("setFormLoadingBegin");
        return ApiService.api.forms.forms_create({ data: payload }).then((result) => {
            console.log(result);
            context.commit("setForm", result);
            context.commit("setFormLoadingComplete");
        }).catch((response) => {
            console.log(response);
            context.commit("setFormErrors", response);
        });
    },
    getForm(context, payload) {
        console.log(payload)
        context.commit("setFormLoadingBegin");
        return ApiService.api.forms.forms_read({ slug: payload }).then((form) => {
            console.log(form);
            context.commit("setForm", form.obj);
            context.commit("setFormLoadingComplete");
        }).catch((response) => {
            console.log(response);
            context.commit("setFormErrors", response);
        });
    },
    putForm(context, payload) {
        console.log(payload)
        context.commit("setFormLoadingBegin");
        const form = payload;
        return ApiService.api.forms.forms_update(form, form.slug).then((result) => {
            console.log(result);
            context.commit("setForm", form);
            context.commit("setFormLoadingComplete");
        }).catch((response) => {
            console.log(response);
            context.commit("setFormErrors", response);
        });
    },
    patchForm(context, payload) {
        return ApiService.api.forms.forms_partial_update({ id: payload.id, data: payload.data }).then((result) => {
            console.log(result);
            return result;
        }).catch((response) => {
            console.log(response);
            return response;
        });
    },
    deleteForm(context, payload) {
        console.log(payload)
        context.commit("setFormLoadingBegin");
        const form = payload;
        ApiService.api.forms.forms_delete(form).then((result) => {
            console.info(result);
            // NOTE (Carlos): Don't set the form to null, you can leave it there just for historic purposes.
            // context.commit("setForm", null);
            context.commit("setFormLoadingComplete");
        }).catch((response) => {
            console.log(response);
            context.commit("setFormErrors", response);
        });
    },
    cloneForm(context, payload) {
        context.commit("setFormLoadingBegin");
        console.log(ApiService.api);
        return ApiService.api.form_rest.form_rest_clone_create({ data: payload }).then((form) => {
            console.log(form);
            context.commit("setWizardForm", form.obj);
            context.commit("setFormLoadingComplete");
            return form;
        }).catch((response) => {
            console.log(response);
            context.commit("setFormErrors", response);
        });
    },
}
const mutations = {
    setFormLoadingBegin(state) {
        state.form_loading = true;
    },
    setFormLoadingComplete(state) {
        state.form_loading = false;
    },
    setForms(state, forms) {
        state.forms = forms;
        state.errors = {};
    },
    setForm(state, form) {
        state.form = form;
        state.errors = {};
    },
    setWizardForm(state, form) {
        state.wizard_form = form;
        state.errors = {};
    },
    setFormErrors(state, errors) {
        state.form_errors = errors;
    }
}

export default {
    state, getters, actions, mutations
}