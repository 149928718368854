import Vue from "vue";
import { BVConfigPlugin, BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import App from "./App.vue";

import store from "@/store/index.js";
import router from "@/router/index.js";

// // Bootstrap
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

// Local
import "./assets/styles/screen.scss";

// Datetimepicker
// https://github.com/chronotruck/vue-ctk-date-time-picker
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

import "vue-select/dist/vue-select.css";

// API
import ApiService from "@/services/api.service.js";
import Vuelidate from "vuelidate";
import VueClipboard from "vue-clipboard2";

import axios from "axios";

import Loading from "@/components/utils/Loading";

import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import values from "lodash/values";
import some from "lodash/some";
import get from "lodash/get";
import sortBy from "lodash/sortBy";

import CKEditor from "@ckeditor/ckeditor5-vue";
import JsonCSV from "vue-json-csv";

import vSelect from "vue-select";

//var VueScrollTo = require("vue-scrollto");

import VueScrollTo from "vue-scrollto";

async function init() {
  await ApiService.init();

  Vue.prototype.$api = ApiService.api;
  Vue.prototype.$apiDefs = ApiService.definitions;
  Vue.prototype.$axios = axios;
  Vue.prototype.$_ = { has, isEmpty, isEqual, get, sortBy, values, some };
  Vue.prototype.$scrollTo = VueScrollTo;

  Vue.use(BVConfigPlugin, {
    BInputGroup: { size: "sm" },
  });

  Vue.use(BootstrapVue);
  Vue.use(BootstrapVueIcons);
  Vue.use(Vuelidate);
  Vue.use(CKEditor);
  Vue.use(VueClipboard);

  Vue.config.devtools = true;

  Vue.component("loading", Loading);
  Vue.component("downloadCsv", JsonCSV);

  Vue.component("v-select", vSelect);

  //Vue.use(VueScrollTo);
}

init().then(() => {
  new Vue({
    el: "#app",
    store,
    router,
    components: {
      app: App,
    },
  }).$mount("#app");
});
