import moment from "moment";

import ApiService from "@/services/api.service.js";

const state = {
	survey_loading: false,
	survey_errors: {},

	survey_to_render: {}
};
const getters = {
	survey_loading(state) {
		return state.survey_loading;
	},
	survey_errors(state) {
		return state.survey_errors;
	},
	survey_to_render(state) {
		return state.survey_to_render;
	}
};
const actions = {
	getSurveyToRender(context, payload) {
		context.commit("setSurveyLoadingBegin");
		return ApiService.api.survey_rest
			.survey_rest_get_read({ slug: payload })
			.then(survey => {
				// console.log(survey);
				context.commit("setSurveyToRender", survey.obj);
				context.commit("setSurveyLoadingComplete");
				return survey;
			})
			.catch(error => {
				// console.log(error.response);
				context.commit("setSurveyErrors", error.response);
				return error.response;
			});
	}
	// getFormEntries(context, payload) {
	//     context.commit("getFormEntriesLoadingBegin");
	//     return ApiService.api.form_entries.form_entries_get_read({ data: payload }).then((formEntries) => {
	//         console.log(formEntries);
	//         context.commit("getFormEntriesToRender", formEntries.obj);
	//         context.commit("getFormEntriesLoadingComplete");
	//     }).catch((response) => {
	//         console.log(response);
	//         context.commit("getFormEntriesErrors", response);
	//     });
	// }
};
const mutations = {
	setSurveyLoadingBegin(state) {
		state.survey_loading = true;
	},
	setSurveyLoadingComplete(state) {
		state.survey_loading = false;
	},
	setSurveyToRender(state, survey) {
		state.survey_to_render = survey;
		state.survey_errors = {};
	},
	setSurveyErrors(state, errors) {
		state.survey_errors = errors;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
