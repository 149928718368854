<template>
  <footer>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </footer>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
