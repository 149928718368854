import Vue from "vue";

const state = {
  // Coding
  vocabsAndTags: [],
  groupsAndLocations: [],

  // Analysing
  filters: {
    all: {
      gender: true,
      nationality: true,
      enrolment: true,
      study: true,
      faculty: true,
      partfulltime: true,
      online: true,
    },
    gender: [],
    nationality: [],
    enrolment: [],
    study: [],
    faculty: [],
    partfulltime: [],
    online: [],
  },
};

const mutations_tags = {
  addVocabsAndTags(state, payload) {
    // console.log(payload);
    state.vocabsAndTags = payload;
  },
  addVocab(state, payload) {
    state.vocabsAndTags.push(payload.data);
  },
  //Expecting you to give an object with an "idVocab" attribute, and a "data" attribute which is an object with all the fields that must be created.
  addTag(state, payload) {
    const vocabIndex = state.vocabsAndTags.findIndex(
      (vocab) => vocab.id === payload.idVocab
    );
    state.vocabsAndTags[vocabIndex].tags.push(payload.data);
  },
  //Expecting you to give an object with an "idVocab" attribute, and a "data" attribute which is an object with all the fields that must change.
  updateVocab(state, payload) {
    const vocabIndex = state.vocabsAndTags.findIndex(
      (vocab) => vocab.id === payload.idVocab
    );
    Object.assign(state.vocabsAndTags[vocabIndex], payload.data);
  },
  //Expecting you to give an object with an "idVocab" and "idTag" attribute, and a "data" attribute which is an object with all the fields that must change.
  updateTag(state, payload) {
    const vocabIndex = state.vocabsAndTags.findIndex((vocab) => {
      return vocab.id === payload.idVocab;
    });
    const tagIndex = state.vocabsAndTags[vocabIndex].tags.findIndex(
      (tag) => tag.id === payload.idTag
    );
    Object.assign(state.vocabsAndTags[vocabIndex].tags[tagIndex], payload.data);
  },
  //The id in the database, not the index of the array.
  deleteVocab(state, idVocab) {
    const vocabIndex = state.vocabsAndTags.findIndex(
      (vocab) => vocab.id === idVocab
    );
    Vue.delete(state.vocabsAndTags, vocabIndex);
  },
  //Expecting in the payload an idVocab and idTag. Both ids are from the database, not indexes of the array.
  deleteTag(state, payload) {
    const vocabIndex = state.vocabsAndTags.findIndex(
      (vocab) => vocab.id === payload.idVocab
    );
    const tagIndex = state.vocabsAndTags[vocabIndex].tags.findIndex(
      (tag) => tag.id === payload.idTag
    );
    Vue.delete(state.vocabsAndTags[vocabIndex].tags, tagIndex);
  },
};

const mutations_locations = {
  addGroupsAndLocations(state, payload) {
    state.groupsAndLocations = payload;
  },
  addLocationGroup(state, payload) {
    state.groupsAndLocations.push(payload.data);
  },
  // Expecting you to give an object with an "idGroup" attribute, and a "data" attribute which is an object with all the fields that must be created.
  // param payload.locationArrayIndex: Optional parameter that inserts location in a specific position. Default is undefined
  addLocation(state, payload) {
    const locationArrayIndex = payload.locationArrayIndex
    const groupIndex = state.groupsAndLocations.findIndex(
      (group) => group.id === payload.idGroup
    );

    if(!Number.isInteger(locationArrayIndex)){
      state.groupsAndLocations[groupIndex].locations.push(payload.data);
    }else{
      // +1 To insert new location _below_ provided index
      state.groupsAndLocations[groupIndex].locations.splice(locationArrayIndex + 1, 0, payload.data);
    }

  },
  //Expecting you to give an object with an "idGroup" attribute, and a "data" attribute which is an object with all the fields that must change.
  updateLocationGroup(state, payload) {
    const groupIndex = state.groupsAndLocations.findIndex(
      (group) => group.id === payload.idGroup
    );
    Object.assign(state.groupsAndLocations[groupIndex], payload.data);
  },
  //Expecting you to give an object with an "idGroup" and "idLocation" attribute, and a "data" attribute which is an object with all the fields that must change.
  updateLocation(state, payload) {
    const groupIndex = state.groupsAndLocations.findIndex((group) => {
      return group.id === payload.idGroup;
    });
    const locationIndex = state.groupsAndLocations[groupIndex].locations.findIndex(
      (location) => location.id === payload.idLocation
    );
    Object.assign(state.groupsAndLocations[groupIndex].locations[locationIndex], payload.data);
  },
  //The id in the database, not the index of the array.
  deleteLocationGroup(state, idGroup) {
    const groupIndex = state.groupsAndLocations.findIndex(
      (group) => group.id === idGroup
    );
    Vue.delete(state.groupsAndLocations, groupIndex);
  },
  //Expecting in the payload an idGroup and idLocation. Both ids are from the database, not indexes of the array.
  deleteLocation(state, payload) {
    const groupIndex = state.groupsAndLocations.findIndex(
      (group) => group.id === payload.idGroup
    );
    const locationIndex = state.groupsAndLocations[groupIndex].locations.findIndex(
      (location) => location.id === payload.idLocation
    );
    Vue.delete(state.groupsAndLocations[groupIndex].locations, locationIndex);
  },
};

const mutations_filters = {
  /*
  Pass object with following structure:
    {
      filter,       //This is a key. Example: Gender.
      obj
    }
  */
  setFilter(state, payload) {
    state.filters[payload.filter] = payload.obj;
  },
  /*
  Pass object with following structure:
    {
      filter,       //This is a key. Example: Gender.
      sub_filter    //This is a key. Example: Male.
      state
    }
  */
  setFilterState(state, payload) {
    // console.log(state.filters[payload.filter]);
    // console.log(state.filters[payload.filter][payload.sub_filter]);
    state.filters[payload.filter][payload.sub_filter].state = payload.state;
  },
  /*
  Pass object with following structure:
    {
      filter,       //This is a key. Example: Gender.
      sub_filter    //This is a key. Example: Male.
    }
  */
  toggleFilterState(state, payload) {
    const newState = !state.filters[payload.filter][payload.sub_filter].state;
    state.filters[payload.filter][payload.sub_filter].state = newState;
  },
  /*
  Pass object with following structure:
    {
      filter,       //This is a key. Example: Gender.
      state
    }
  */
  setAllFilterState(state, payload) {
    state.filters.all[payload.filter] = payload.state;
  },
  //The payload is the filter key.
  setFilterValuesToFalse(state, filter) {
    state.filters[filter].map((g) => (g.state = false));
  },
  //The payload is the filter key.
  setFilterAllToFalse(state, filter) {
    state.filters.all[filter] = false;
  },
  resetFilters(state) {
    state.filters.all["gender"] = true;
    state.filters.all["nationality"] = true;
    state.filters.all["enrolment"] = true;
    state.filters.all["study"] = true;
    state.filters.all["faculty"] = true;
    state.filters.all["partfulltime"] = true;
    state.filters.all["online"] = true;

    state.filters["gender"].map((g) => (g.state = false));
    state.filters["nationality"].map((g) => (g.state = false));
    state.filters["enrolment"].map((g) => (g.state = false));
    state.filters["study"].map((g) => (g.state = false));
    state.filters["faculty"].map((g) => (g.state = false));
    state.filters["partfulltime"].map((g) => (g.state = false));
    state.filters["online"].map((g) => (g.state = false));
  },
};

const mutations = {
  ...mutations_tags,
  ...mutations_locations,
  ...mutations_filters,
};

const actions = {};

const getters_tags = {
  vocabs_and_tags: (state) => {
    return state.vocabsAndTags;
  },
  sorted_vocabs_and_tags: (state) => {
    let sorted = [];
    let keysOrdered = Object.keys(state.vocabsAndTags);
    keysOrdered.sort((a, b) => {
      let v1 = state.vocabsAndTags[a].name.toLowerCase();
      let v2 = state.vocabsAndTags[b].name.toLowerCase();
      if (v1 > v2) {
        return 1;
      } else if (v1 < v2) {
        return -1;
      } else {
        return 0;
      }
    });

    keysOrdered.forEach((key) => {
      sorted.push(state.vocabsAndTags[key]);
    });

    for (let v of sorted) {
      let sortedTags = [];
      let keysOrderedTags = Object.keys(v.tags);
      keysOrderedTags.sort((a, b) => {
        let t1 = v.tags[a].name.toLowerCase();
        let t2 = v.tags[b].name.toLowerCase();
        if (t1 > t2) {
          return 1;
        } else if (t1 < t2) {
          return -1;
        } else {
          return 0;
        }
      });

      keysOrderedTags.forEach((keyTags) => {
        sortedTags.push(v.tags[keyTags]);
      });

      v.tags = sortedTags;
    }

    console.log(sorted);

    return sorted;
  },
  tags_by_id: (state) => (idVocab) => {
    const vocabIndex = state.vocabsAndTags.findIndex(
      (vocab) => vocab.id === idVocab
    );
    return state.vocabsAndTags[vocabIndex].tags;
  },
  just_tags: (state) => {
    let all_children = [];
    for (let vocab of state.vocabsAndTags) {
      all_children.push(vocab.tags);
    }
    return [].concat.apply([], all_children);
  },
  tags_grouped_by_vocab: (state) => {
    let result = [];
    for (const vocab of state.vocabsAndTags) {
      result.push({
        label: vocab.name,
        options: vocab.tags.map((tag) => {
          return { value: tag, text: tag.name };
        }),
      });
    }
    return result;
  },
  ordered_tags_grouped_by_vocab: (state) => {
    let result = [];
    for (const vocab of state.vocabsAndTags) {
      result.push(
        ...vocab.tags.map((tag) => {
          return {
            tagName: tag.name,
            vocabName: vocab.name,
            vocabId: vocab.id,
            code: tag.id,
          };
        })
      );
    }
    return result.sort((a, b) => (a.tagName > b.tagName ? 1 : -1));
  },
};

const getters_locations = {
  groupsAndLocations: (state) => {
    return state.groupsAndLocations;
  },
  locations_id_and_name_by_group_id: (state) => (idGroup) => {
    const groupIndex = state.groupsAndLocations.findIndex(
      (group) => group.id === idGroup
    );
    return state.groupsAndLocations[groupIndex].locations.map((location) => ({
      value: location.id,
      text: location.level === 0 ? location.name : unescape("\xa0\xa0\xa0\xa0".repeat(location.level) + location.name),
    }));
  },
  locations_by_id: (state) => (idGroup) => {
    const groupIndex = state.groupsAndLocations.findIndex(
      (group) => group.id === idGroup
    );
    return state.groupsAndLocations[groupIndex].locations;
  },
};

const getters_filters = {
  filters: (state) => {
    return state.filters;
  },
  getFilterValues: (state) => (filter) => {
    let values = [];
    values = state.filters[filter].filter((g) => g.state).map((g) => g.id);
    if (values.length === 0) {
      return "0";
    }
    return values.join(",");
  },
  getFilterValuesForPreset: (state) => (filter) => {
    let values = [];
    if (state.filters.all[filter]) {
      values.push(0);
    } else {
      values = state.filters[filter].filter((g) => g.state).map((g) => g.id);
    }
    return values;
  },
  isAllSelectedForAllFilters: (state) => {
    return (
      state.filters.all.gender &&
      state.filters.all.nationality &&
      state.filters.all.enrolment &&
      state.filters.all.study &&
      state.filters.all.faculty &&
      state.filters.all.partfulltime &&
      state.filters.all.online
    );
  },
};

const getters = {
  ...getters_tags,
  ...getters_locations,
  ...getters_filters,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
