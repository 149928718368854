<template>
	<div>
		<MyHeader v-if="['survey'].indexOf($route.name) <= -1 && users_is_user_logged"></MyHeader>
		<b-row class="outer-section">
			<b-col class="pt-2"></b-col>
		</b-row>
		<router-view></router-view>
		<MyFooter></MyFooter>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import Header from "@/components/header";
import Footer from "@/components/footer";

export default {
	name: "App",
	components: {
		MyHeader: Header,
		MyFooter: Footer
	},
	data() {
		return {
			msg: "Welcome to Your Vue.js App"
		};
	},
	computed: {
		...mapGetters(["users_is_user_logged"])
	},
	mounted() {
		// Initialize definitions that'll allow us to retrieve
		// field properties later
		//this.$store.dispatch("getDefinitions");
	}
};
</script>
