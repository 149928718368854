import ApiService from '@/services/api.service.js'

const state = {
    contact_loading: false,
    contact_errors: {},

    contacts: [],
    contact: {},
}
const getters = {
    contact_loading(state) {
        return state.contact_loading;
    },
    contact(state) {
        return state.contact;
    },
    contacts(state) {
        return state.contacts;
    },
}
const actions = {
    getContacts(context, payload) {
        context.commit("setContactLoadingBegin");
        return ApiService.api.contacts.contacts_list().then((contacts) => {
            console.log(contacts);
            context.commit("setContacts", contacts.obj);
            context.commit("setContactLoadingComplete");
        }).catch((response) => {
            console.log(response);
            context.commit("setContactErrors", response);
        });
    },
    postContact(context, payload) {
        console.log(payload)
        context.commit("setContactLoadingBegin");
        return ApiService.api.contacts.contacts_create({ data: payload }).then((result) => {
            console.log(result);
            context.commit("setContact", result);
            context.commit("setContactLoadingComplete");
            return result;
        }).catch((error) => {
            console.log(error.response);
            context.commit("setContactErrors", error.response);
        });
    },
    getContact(context, payload) {
        console.log(payload)
        context.commit("setContactLoadingBegin");
        return ApiService.api.contacts.contacts_read({ id: payload }).then((contact) => {
            console.log(contact);
            context.commit("setContact", contact.obj);
            context.commit("setContactLoadingComplete");
        }).catch((response) => {
            console.log(response);
            context.commit("setContactErrors", response);
        });
    },
    putContact(context, payload) {
        console.log(payload)
        context.commit("setContactLoadingBegin");
        const contact = payload;
        return ApiService.api.contacts.contacts_update({ id: payload.id, body: payload }).then((result) => {
            console.log(result);
            context.commit("setContact", result);
            context.commit("setContactLoadingComplete");
        }).catch((response) => {
            console.log(response);
            context.commit("setContactErrors", response);
        });
    },
    patchContact(context, payload) {
        context.commit("setContactLoadingBegin");
        return ApiService.api.contacts.contacts_partial_update({ id: payload.id, data: payload.data }).then((result) => {
            context.commit("setContactLoadingComplete");
            return result;
        }).catch((response) => {
            console.log(response);
            context.commit("setContactErrors", response);
        });
    },
    deleteContact(context, payload) {
        context.commit("setContactLoadingBegin");
        return ApiService.api.contacts.contacts_delete({ id: payload.id }).then((result) => {
            var objList = state.contacts
            var objIndex = objList.indexOf(payload);
            if (objIndex > -1) {
                objList.splice(objIndex, 1);
                context.commit("setContacts", objList);
            }
            context.commit("setContactLoadingComplete");
        });
    }
}
const mutations = {
    setContactLoadingBegin(state) {
        state.contact_loading = true
    },
    setContactLoadingComplete(state) {
        state.contact_loading = false
    },
    setContacts(state, contacts) {
        state.contacts = contacts
        state.errors = {}
    },
    setContact(state, contact) {
        state.contact = contact
        state.errors = {}
    },
    setContactErrors(state, errors) {
        state.contact_errors = errors
    },
}

export default {
    state, getters, actions, mutations
}