import ApiService from '@/services/api.service.js'

const state = {
    client_loading: false,
    client_errors: {},

    clients: [],
    // object must not be undefined in order to allow form rendering of a new object
    client: {},

    client_country_list: [],
}
const getters = {
    client_loading(state) {
        return state.client_loading;
    },
    client(state) {
        return state.client;
    },
    clients(state) {
        return state.clients;
    },
    client_country_list(state) {
        return state.client_country_list;
    },
}
const actions = {
    getClientCountryList(context, payload) {
        context.commit("setClientCountryList", ApiService.definitions.Client.properties.country_dict.enum);
    },
    getClients(context, payload) {
        context.commit("setClientLoadingBegin");
        return ApiService.api.clients.clients_list().then((clients) => {
            context.commit("setClients", clients.obj);
            context.commit("setClientLoadingComplete");
        }).catch((response) => {
            console.log(response);
            context.commit("setClientErrors", response);
        });
    },
    postClient(context, payload) {
        console.log(payload)
        context.commit("setClientLoadingBegin");
        return ApiService.api.clients.clients_create({ data: payload }).then((result) => {
            context.commit("setClient", result.obj);
            context.commit("setClientLoadingComplete");
            return result;
        }).catch((error) => {
            console.log(error.response);
            context.commit("setClientErrors", error);
            return error.response;
        });
    },
    getClient(context, payload) {
        // payload is an int (ID parameter)
        context.commit("setClientLoadingBegin");
        return ApiService.api.clients.clients_read({ id: payload }).then((client) => {
            // console.log(client);
            context.commit("setClient", client.obj);
            context.commit("setClientLoadingComplete");
        });
    },
    storeClient(context, clientObj) {
        // Simply stores a client object. Alternative to getClient that
        // avoids calling the API
        context.commit("setClient", clientObj);
    },
    putClient(context, payload) {
        return ApiService.api.clients.clients_update({ id: payload.id, data: payload }).then((result) => {
            if(result.obj !== undefined){
                context.commit("setClient", result.obj);
            }
            return result;
        });
    },
    patchClient(context, payload) {
        return ApiService.api.clients.clients_partial_update({ id: payload.id, data: payload.data }).then((result) => {
            return result;
        }).catch((response) => {
            console.log(response.response);
            return response.response;
        });
    },
    deleteClient(context, payload) {
        context.commit("setClientLoadingBegin");
        return ApiService.api.clients.clients_delete({ id: payload.id }).then((result) => {
            var objList = state.clients
            var objIndex = objList.indexOf(payload);
            if (objIndex > -1) {
                objList.splice(objIndex, 1);
                context.commit("setClients", objList);
            }
            context.commit("setClientLoadingComplete");
        });
    },
    resetClient(context) {
        console.log("Resetting cached client...")
        context.commit("setClient", {});
    },
}

const mutations = {
    setClientLoadingBegin(state) {
        state.client_loading = true
    },
    setClientLoadingComplete(state) {
        state.client_loading = false
    },
    setClients(state, clients) {
        state.clients = clients
        state.errors = {}
    },
    setClient(state, client) {
        state.client = client
        state.errors = {}
    },
    setClientErrors(state, errors) {
        state.client_errors = errors
    },
    setClientCountryList(state, client_country_list) {
        state.client_country_list = client_country_list
        state.errors = {}
    },
}

export default {
    state, getters, actions, mutations
}