import ApiService from "@/services/api.service.js";

const state = {
	project_loading: false,
	project_errors: undefined,

	projects: [],
	project: {}
};
const getters = {
	project_loading(state) {
		return state.project_loading;
	},
	project(state) {
		return state.project;
	},
	projects(state) {
		return state.projects;
	},
	project_errors(state) {
		return state.project_errors;
	}
};
const actions = {
	getProjects(context, payload) {
		context.commit("setProjectLoadingBegin");
		return ApiService.api.projects
			.projects_list()
			.then(projects => {
				context.commit("setProjects", projects.obj);
				context.commit("setProjectLoadingComplete");
			})
			.catch(response => {
				context.commit("setProjectErrors", response.response);
				context.commit("setProjectLoadingComplete");
			});
	},
	postProject(context, payload) {
		if (payload.date_start === undefined) {
			delete payload.date_start;
		}
		if (payload.date_end === undefined) {
			delete payload.date_end;
		}
		if (payload.date_start !== undefined && payload.date_start !== undefined) {
			payload.date_start = payload.date_start + "T" + payload.time_start + ":00.000Z";
			payload.date_end = payload.date_end + "T" + payload.time_end + ":00.000Z";
		}
		delete payload.time_start;
		delete payload.time_end;

		const csrftoken = window.getCookie("csrftoken");
		payload["X-CSRFToken"] = csrftoken;

		console.log(payload);
		context.commit("setProjectLoadingBegin");

		return ApiService.api.projects
			.projects_create({ data: payload, headers: { X_CSRFToken: csrftoken } })
			.then(result => {
				console.log(result);
				context.commit("setProject", result.obj);
				context.commit("setProjectLoadingComplete");
				return result;
			})
			.catch(response => {
				context.commit("setProjectErrors", response.response);
				context.commit("setProjectLoadingComplete");
			});
	},
	getProject(context, payload) {
		context.commit("setProjectLoadingBegin");
		return ApiService.api.projects.projects_read({ id: payload }).then(project => {
			context.commit("setProject", project.obj);
			context.commit("setProjectLoadingComplete");
			return project;
		});
	},
	putProject(context, payload) {
		return ApiService.api.projects.projects_update({ id: payload.id, data: payload }).then(result => {
			if (result.obj !== undefined) {
				context.commit("setProject", result.obj);
			}
			return result;
		});
	},
	patchProject(context, payload) {
		context.commit("setProjectLoadingBegin");
		return ApiService.api.projects
			.projects_partial_update({ id: payload.id, data: payload.data })
			.then(result => {
				context.commit("setProjectLoadingComplete");
				return result;
			})
			.catch(response => {
				context.commit("setProjectErrors", response.response);
				context.commit("setProjectLoadingComplete");
			});
	},
	deleteProject(context, payload) {
		context.commit("setProjectLoadingBegin");

		return ApiService.api.projects.projects_delete({ id: payload.id }).then(result => {
			var objList = state.projects;
			var objIndex = objList.indexOf(payload);
			if (objIndex > -1) {
				objList.splice(objIndex, 1);
				context.commit("setProjects", objList);
			}
			context.commit("setProjectLoadingComplete");
		});
	}
};
const mutations = {
	setProjectLoadingBegin(state) {
		state.project_loading = true;
	},
	setProjectLoadingComplete(state) {
		state.project_loading = false;
	},
	setProjects(state, projects) {
		state.projects = projects;
		state.errors = {};
	},
	setProject(state, project) {
		state.project = project;
		state.errors = {};
	},
	setProjectErrors(state, errors) {
		state.project_errors = errors;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
