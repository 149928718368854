import Vue from "vue";
import Vuex from "vuex";

import projects from "@/store/projects.module.js";
import clients from "@/store/clients.module.js";
import contacts from "@/store/contacts.module.js";
import forms from "@/store/forms.module.js";
import wizard from "@/store/wizard.module.js";
import surveys from "@/store/surveys.module.js";
import users from "@/store/users.module.js";

import project_process from "@/store/project_process.module.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    projects,
    clients,
    contacts,
    forms,
    surveys,
    users,

    wizard,
    project_process
  },
  strict: process.env.NODE_ENV !== "production"
});
