import Vue from "vue";
import Router from "vue-router";

import store from "@/store/index.js";
import EmptyRouterView from "@/views/EmptyRouterView";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/projects/",
      component: EmptyRouterView,
      children: [
        {
          path: "",
          name: "projects_list",
          component: () => import("@/views/projects/ProjectList")
        },
        {
          path: ":id",
          name: "project_update",
          component: () => import("@/views/projects/ProjectUpdate")
        },
        {
          path: "data",
          component: EmptyRouterView,
          children: [
            {
              path: ":id",
              name: "project_process",
              component: () => import("@/views/projects/ProjectProcess")
            },
            {
              path: ":id/comments/:questionGroupId?",
              name: "project_process_comments",
              component: () => import("@/views/projects/ProjectComments"),
              props: true
            }
          ]
        }
      ]
    },
    {
      path: "/clients/",
      component: EmptyRouterView,
      children: [
        {
          path: "",
          name: "clients_list",
          component: () => import("@/views/clients/ClientsList")
        },
        {
          path: "new",
          name: "client_create",
          component: () => import("@/views/clients/ClientCreate")
        },
        {
          path: ":id",
          name: "client_update",
          component: () => import("@/views/clients/ClientUpdate")
        }
      ]
    },
    {
      path: "/users/",
      component: EmptyRouterView,
      children: [
        {
          path: "",
          name: "users_list",
          component: () => import("@/views/users/UsersList")
        },
        {
          path: "new",
          name: "user_create",
          component: () => import("@/views/users/UserCreate")
        },
        {
          path: ":id",
          name: "user_update",
          component: () => import("@/views/users/UserUpdate")
        }
      ]
    },
    {
      path: "/surveys/",
      component: EmptyRouterView,
      children: [
        {
          path: ":slug",
          name: "survey",
          component: () => import("@/views/Survey")
        }
      ]
    },
    {
      path: "/wizard",
      name: "wizard",
      component: () => import("@/views/Wizard")
    },
    {
      path: "/",
      name: "home",
      redirect: { name: "projects_list" }
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  let logged_user = null;
  try {
    await store.dispatch("getLoggedUser");
    logged_user = await store.getters.users_get_logged_user;
    logged_user = JSON.parse(JSON.stringify(logged_user));
  } catch (ex) {
    // console.log(ex);
    logged_user = { id: null };
  }

  if (to.name !== "survey") {
    if (logged_user.id === null) {
      window.location.href = `/admin/login?next=/#/`;
    } else {
      next();
    }

    if (
      to.name === "users_list" ||
      to.name === "user_create" ||
      to.name === "user_update"
    ) {
      if (logged_user.id !== null && logged_user.is_superuser) {
        next();
      } else {
        next({ name: "home" });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
