<template>
  <b-navbar toggleable="sm" type="dark" variant="dark" class="mb-3" sticky>
    <div class="container">
      <b-navbar-brand :to="{ name: 'home' }">Campus App</b-navbar-brand>
      <b-navbar-nav>
        <b-nav-item v-if="users_get_logged_user.is_superuser" :to="{ name: 'users_list' }">Users</b-nav-item>
        <b-nav-item :to="{ name: 'clients_list' }">Clients</b-nav-item>
        <b-nav-item right>
          <span>{{ users_get_logged_user.email }}</span>
          <b-icon-box-arrow-right variant="light" @click="logout"></b-icon-box-arrow-right>
        </b-nav-item>
      </b-navbar-nav>
    </div>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["users_get_logged_user", "users_is_user_logged"])
  },
  methods: {
    logout: function() {
      window.location.href = "/admin/logout?next=/";
    }
  }
};
</script>
