import Swagger from "swagger-client";

//console.log(client.spec);			//The resolved spec.
//console.log(client.originalSpec);	//In case you need it.
//console.log(client.errors);		//Any resolver errors.

function client_header_injector(req) {
  if (!req.headers["X-CSRFToken"]) {
    req.headers["x-CSRFToken"] = window.getCookie("csrftoken");
  }
}

async function getClient() {
  return await Swagger({
    url: "/swagger.json",
    requestInterceptor: req => client_header_injector(req),
    responseInterceptor: function(data) {
      //If at the response interceptor we find a 403, it means that the session for that user is expired.
      //Hence, we redirect them to the login page.

      if (data.status === 403) {
        window.location.href = `/admin/login?next=/#/`;
      }
    }
  });
}

const ApiService = {
  client: {},
  api: {},
  definitions: {},
  async init() {
    this.client = await getClient();
    // console.log(this.client);
    this.api = this.client.apis;
    this.definitions = this.client.spec.definitions;
  }
};

export default ApiService;
