import ApiService from "@/services/api.service.js";

const state = {
  logged_user: null,
  is_user_logged: false
};
const getters = {
  users_get_logged_user(state) {
    return state.logged_user;
  },
  users_is_user_logged(state) {
    return state.is_user_logged;
  }
};
const actions = {
  async getLoggedUser(context) {
    const user = await ApiService.api.users.users_logged_user();
    try {
      context.commit("setUsersLoggedUser", user.obj);
      if (user.obj.id === null) {
        context.commit("setUsersIsUserLogged", false);
      } else {
        context.commit("setUsersIsUserLogged", true);
      }
      return user.obj;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
};
const mutations = {
  setUsersLoggedUser(state, user) {
    state.logged_user = user;
  },
  setUsersIsUserLogged(state, is_user_logged) {
    state.is_user_logged = is_user_logged;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
